<template>
  <a-button key="submit" type="primary" _loading="loading" @click="add" v-if="!$props.readOnly"
    >Tambah</a-button
  >
  <a-table :columns="columns" :data-source="dataSource" bordered>
    <template v-for="col in ['name']" #[col]="{ text, index }" :key="col">
      <div>
        <a-input
          v-if="editableData[index]"
          v-model:value="editableData[index][col]"
          style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #operation="{  index }">
      <div class="editable-row-operations" v-if="!$props.readOnly">
        <span v-if="editableData[index]">
          <a @click="save(index)">Simpan</a>
          <a @click="cancel(index)">Batal</a>
        </span>
        <span v-else>
          <a @click="edit(index)">Ubah</a>
          <a @click="() => remove(index)">Hapus</a>
        </span>
      </div>
    </template>
  </a-table>
</template>
<script>
import { cloneDeep } from 'lodash'
import { defineComponent, reactive, ref, computed } from 'vue'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Nama Paket',
    dataIndex: 'name',
    slots: {
      customRender: 'name',
    },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    slots: {
      customRender: 'operation',
    },
  },
]

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dataSource = computed(() => props.data)

    const editableData = reactive({})

    const edit = key => {
      editableData[key] = cloneDeep(dataSource.value[key])
    }

    const save = key => {
      if (!editableData[key].name) {
        message.error('Nama tidak bisa kosong!')
        return
      }
      Object.assign(dataSource.value[key], editableData[key])
      delete editableData[key]
    }

    const cancel = key => {
      delete editableData[key]
      if (!dataSource.value[key].name) {
        remove(key)
      }
    }

    const add = () => {
      if (editableData[0]) return

      dataSource.value.unshift({ name: undefined })
      editableData[0] = cloneDeep(dataSource.value[0])
    }

    const remove = index => {
      dataSource.value.splice(index, 1)
    }

    return {
      dataSource,
      columns,
      editingKey: '',
      editableData,
      add,
      remove,
      edit,
      save,
      cancel,
    }
  },
})
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
