<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button
          class="ml-2 mt-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          title="Tambah Konfigurasi Up Front"
          class="m-2"
          @click="show(null)"
        >
          <i class="fa fa-plus"></i>
        </a-button>
        <a-button
          class="m-2"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <md-table
            :columns="columns"
            :data-source="data"
            size="small"
            row-key="id"
            :meta="meta"
            @change="handleChange"
            :loading="isFetching"
          >
            <template #packages="{text}">
              <span>{{ text.map(p => p.name).join(', ') }}</span>
            </template>
            <template #formatDate="{text}">
              <span>{{ moment(text).format('DD-MM-yyyy') }}</span>
            </template>
            <template #action="{record}">
              <span>
                <a-tooltip title="Lihat Program">
                  <a
                    href="javascript: void(0);"
                    class="btn btn-sm btn-light"
                    @click="show(record.id, { readOnly: true })"
                  >
                    <i class="fe fe-eye" />
                  </a>
                </a-tooltip>
                <a-tooltip title="Ubah Program">
                  <a
                    href="javascript: void(0);"
                    class="btn btn-sm btn-light"
                    @click="show(record.id)"
                  >
                    <small>
                      <i class="fe fe-edit" />
                    </small>
                  </a> </a-tooltip
                ><a-tooltip title="Hapus Program">
                  <a
                    href="javascript: void(0);"
                    class="btn btn-sm btn-light"
                    @click="hapus(record.id)"
                  >
                    <small>
                      <i class="fe fe-trash" />
                    </small>
                  </a>
                </a-tooltip>
              </span>
            </template>
          </md-table>
        </div>
      </div>
    </div>
    <v-modal
      v-if="visible"
      :id="modalId"
      :visible="visible"
      :options="options"
      @visible="v => visibleChanged(v)"
    ></v-modal>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { columns } from './column'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'
import VModal from './modal'
import { Modal, message } from 'ant-design-vue'

const initial = () => ({
  id: null,
  name: null,
  description: null,
  active: null,
})

// http://api-mdxl.aksestoko.com/sample-upload/loyalty-target.xlsx
export default {
  components: { VModal },
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },

      isFetching: false,
      isDownloading: false,
      search: null,
      q: null,
      params: [],
      visible: false,
      modalId: null,
      options: {},
    })

    const formState = reactive({ ...initial() })

    const handleChange = (pag, filters, sorter) => {
      state.meta.currentPage = pag.current
      state.meta.perPage = pag.pageSize

      fetchData()
    }

    const fetchData = () => {
      const _params = {
        page: state.meta.currentPage,
        'per-page': state.meta.perPage,
        q: state.q,
        active: formState.active,
      }
      state.params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/loyalty-programs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/loyalty-programs', {
          params: {
            ...state.params.value,
            _export: 'xls',
            _columns: columns
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `loyalty-programs_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const hapus = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus Program ?',
        onOk() {
          apiClient
            .delete(`/api/loyalty-programs/${id}`)
            .then(response => {
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    const show = (id, options = { readOnly: false }) => {
      state.visible = true
      state.modalId = id
      state.options = options
    }
    const visibleChanged = visible => {
      state.visible = visible
      if (!visible) {
        fetchData()
      }
    }
    return {
      handleChange,
      ...toRefs(state),
      columns,
      fetchXlsx,
      search,
      moment,
      hapus,
      show,
      visibleChanged,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
</style>
