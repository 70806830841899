export const columns = [
  {
    title: 'No',
    dataIndex: 'id',
    slots: { customRender: 'no' },
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Nama Program',
    dataIndex: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type_name',
  },
  {
    title: 'Tgl Mulai',
    dataIndex: 'begin_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Tgl Akhir',
    dataIndex: 'end_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Paket',
    dataIndex: 'packages',
    slots: { customRender: 'packages' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
