<template>
  <a-modal
    :visible="visible"
    title="Konfigurasi Program"
    width="65%"
    destroy-on-close
    :mask-closable="false"
  >
    <template #footer>
      <a-button key="submit" type="primary" :loading="loading" @click="save">Simpan</a-button>
      <a-button key="back" @click="cancel">Cancel</a-button>
    </template>

    <a-form
      :wrapper-col="{ span: 14 }"
      class="myform"
      :loading="isFetching"
      ref="formRef"
      _rules="rules"
      :model="state.model"
    >
      <a-row class="form-row">
        <a-col :sm="24"></a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24"></a-col>
      </a-row>
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Program"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-input placeholder="" v-model:value="name" :disabled="readOnly" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tanggal Mulai"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-date-picker
              size="small"
              type="text"
              stylea="width: 150px;"
              :disabled-date="c => disabledStartDated(c, end_date)"
              v-model:value="begin_date"
              :disabled="readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tanggal Akhir"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-date-picker
              size="small"
              type="text"
              stylea="width: 150px"
              :disabled-date="c => disabledEndDated(begin_date, c)"
              v-model:value="end_date"
              :disabled="readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tipe"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <filter-loyalty-type
              v-model:value="type"
              :mode="null"
              :disabled="readOnly"
            ></filter-loyalty-type>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" vif="type !== 1">
        <a-col :sm="24">
          <a-form-item
            label="Paket"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <table-package v-model:data="packages" v-model:readOnly="readOnly"></table-package>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- <pre>{{ state }}</pre> -->
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import TablePackage from './tablePackage'
import FilterLoyaltyType from '@/components/filter/FilterLoyaltyType'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

import { useDisabledDate } from '@/composables/DisabledDate'

export default {
  components: { TablePackage, FilterLoyaltyType },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['visible'],
  setup(props, { emit }) {
    const { disabledStartDated, disabledEndDated, startDate, endDate } = useDisabledDate()

    const state = reactive({
      loading: false,
      model: { id: null, name: null, begin_date: null, end_date: null, type: null, packages: [] },
      id: props.id,
      isFetching: false,
      options: props.options,
      disabledEndDated,
      disabledStartDated,
      startDate,
      endDate,
    })
    const formRef = ref('')

    const cancel = () => {
      emit('visible', false)
    }

    onMounted(async () => {
      if (state.id) {
        await fetchData(state.id)
      }
    })

    const fetchData = async id => {
      state.isFetching = true
      const response = await apiClient.get('/api/loyalty-programs/' + id)
      const data = response.data
      Object.assign(state.model, data)
      state.isFetching = false
    }

    // copy from kelola pengguna
    const save = async () => {
      state.loading = true
      try {
        await formRef.value.validate()
      } catch (error) {
        console.log(error)
        return
      }

      try {
        let update = ''
        if (state.model.id) {
          update = `/${state.model.id}?_method=PUT`
        }
        const result = await apiClient.post(`/api/loyalty-programs` + update, state.model)

        message.success('Berhasil disimpan')
        emit('visible', false)
      } catch (error) {
        message.error('Gagal menyimpan data!')
      } finally {
        state.loading = false
      }
    }

    return {
      save,
      cancel,
      ...toRefs(state.model),
      model: state.model,
      ...toRefs(state),
      state,
      ...toRefs(state.options),
      formRef,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
